import { UserData, UserDataDev } from "../interfaces/data";
import { getGraphData } from "./ssoauthhelper";
import addinConfig from "./addin-config";
import constants from "./constants";
import UIStrings from "./translations";

function parseHTML(html: string) {
    return (new DOMParser()).parseFromString(html, 'text/html');
}

function isClientSignatureEnabled(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        // Check if the client signature is currently enabled.
        Office.context.mailbox.item.isClientSignatureEnabledAsync(function(asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value);
            } else {
                reject(asyncResult.error);
            }
        });
    });
}

function disableClientSignature(): Promise<any> {
    return new Promise((resolve, reject) => {
        // Disable the client signature.
        Office.context.mailbox.item.disableClientSignatureAsync(function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(1);
            } else {
                reject(asyncResult.error);
            }
        });
    });
}

function getComposeType(): Promise<any> {
    return new Promise((resolve, reject) => {
        // Get the compose type of the current item (mail only).
        Office.context.mailbox.item.getComposeTypeAsync(function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value);
            } else {
                reject(asyncResult.error);
            }
        });
    });
}

async function setSignature(signature: UserData['Signatures'][0], noSignatureAdjustment = false): Promise<any> {
    try {
        let signatureToSet = '<div class="mailtastic-email-signature" id="mailtastic-email-signature-'+ signature.id +'-' + (+new Date()) + '">' + signature.HTML + '</div>';

        if (!noSignatureAdjustment) {
            signatureToSet = await adjustSignature(signatureToSet);
        }

        await new Promise((resolve, reject) => {
            Office.context.mailbox.item.body.setSignatureAsync(
                signatureToSet,
                {
                    coercionType: "html"
                },
                asyncResult => {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        resolve(1);
                    } else {
                        reject(asyncResult.error);
                    }
                }
            );
        });
    } catch (error) {
        throw error;
    }
}

function mapToUserData(data: UserDataDev): UserData {
    let serverAPIURL;

    if (constants.mailtastic) {
        serverAPIURL = 'https://www.app.mailtastic.de';
    } else if (constants.cognism) {
        serverAPIURL = 'https://www.signatures.cognism.com';
    } else {
        serverAPIURL = 'https://www.app.mailtastic.de';
    }

    const res = {
        Settings: {
            appendDomains: data && data.settings && data.settings.appendDomains,
            appendFullAdress: data && data.settings && data.settings.appendFullAdress,
            userName: (data && data.validmailadresses && data.validmailadresses[0] && data.validmailadresses[0].userName) || '',
            email: (data && data.validmailadresses && data.validmailadresses[0] && data.validmailadresses[0].email) || '',
            adminUserName: data && data.adminFirstName + ' ' + data.adminLastName,
            adminEmail: data && data.adminEmail,
            validEmailAddresses: data && data.validmailadresses && data.validmailadresses.map(vma => vma.email),
            personalDataEditLink: `${serverAPIURL}/#/datacompletion/employee?ac=&eid=` + ((data && data.validmailadresses && data.validmailadresses[0] && data.validmailadresses[0].id) || '')
        },
        Signatures: (data && data.signatures && data.signatures.map(signature => ({
            Title: signature.title,
            HTML: signature.snippet.replace(new RegExp('http://', 'g'), 'https://'),
            isDefaultNewMail: signature.isDefaultNewMail,
            isDefaultReply: signature.isDefaultResponseMail,
            id: signature.id
        }))) || [],
        latestUpdate: +new Date()
    };

    return res;
}

async function highlightActiveSignature(signatures: UserData['Signatures'] = []) {
    try {
        const msgBody = await getMessageBody();

        if (!msgBody) {
            for (let signature of signatures) {
                signature.checked = false;
            }
            return signatures;
        }

        const isMTSignatureInserted = await isMailtasticSignatureInserted(msgBody);

        if (!isMTSignatureInserted) {
            for (let signature of signatures) {
                signature.checked = false;
            }
            return signatures;
        }

        const bodyDOM = parseHTML(msgBody);

        for (let signature of signatures) {
            signature.checked = !!bodyDOM.querySelector('[id^="x_mailtastic-email-signature-' + signature.id + '-"]');
        }

        return signatures;
    } catch (error) {
        throw error;
    }
}

async function getAppliedSignature(signatures: UserData['Signatures']) {
    try {
        const msgBody = await getMessageBody();

        let signature = null;

        if (!msgBody) {
            return signature;
        }

        const isMTSignatureInserted = await isMailtasticSignatureInserted(msgBody);

        if (!isMTSignatureInserted) {
            return signature;
        }

        const bodyDOM = parseHTML(msgBody);

        signature = signatures.find(s => !!bodyDOM.querySelector('[id^="x_mailtastic-email-signature-' + s.id + '-"]'));

        return signature;
    } catch (error) {
        throw error;
    }
}

function getMessageBody(): Promise<string> {
    return new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, result => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                reject(result.error);
            } else {
                resolve(result.value);
            }
        });
    });
}

async function isMailtasticSignatureInserted(msgBody?: string): Promise<boolean> {
    try {
        msgBody = (msgBody || await getMessageBody());

        if (!msgBody) {
            return false;
        } else {
            const bodyDOM = parseHTML(msgBody);

            const signatureElement = bodyDOM.querySelector('[id^="x_mailtastic-email-signature-"]');

            return !!(signatureElement && (signatureElement.textContent || signatureElement.querySelector('img')));
        }
    } catch (error) {
        throw error;
    }
}

async function getSignatureList(force?: boolean, noFallback?: boolean): Promise<UserData> {
    try {
        if (!force) {
            // check the timestamp of the latest signature refresh
            const userData: UserData = addinConfig.getUserData();

            if (!userData) {
                return;
            }

            if (userData && ((+new Date()) - userData.latestUpdate < 1000 * 60 /* 1min */)) {  // TODO adjust
                return userData;
            }
        }

        const userDataDev: UserDataDev = await getGraphData(noFallback);

        if (userDataDev) {
            const userData: UserData = mapToUserData(userDataDev);

            await new Promise((resolve, reject) => addinConfig.setUserData(userData, resolve));
            return userData;
        }
    } catch (error) {
        throw error;
    }
}

async function displayConnectInfobarAsync() {
    displayConnectInfobar();
}

async function setDefaultSignature(): Promise<void> {
    try {
        const userData: UserData = Office.context.roamingSettings.get("userData");
        let defaultSignature;

        if (!(userData && userData.Signatures && userData.Signatures.length)) {
            displayConnectInfobar();
            return;
        }

        const composeType = await getComposeType();
        switch (composeType.composeType) {  // TODO also by coercion?
            case Office.MailboxEnums.ComposeType.NewMail: {
                defaultSignature = userData.Signatures.find(s => s.isDefaultNewMail);
                break;
            }
            case Office.MailboxEnums.ComposeType.Reply: {
                defaultSignature = userData.Signatures.find(s => s.isDefaultReply);
                break;
            }
            case Office.MailboxEnums.ComposeType.Forward: {
                console.warn('Forward compose type not implemented yet.');
                break;
            }
        }

        if (defaultSignature) {
            await setSignature(defaultSignature)
        }
    } catch (error) {
        throw error;
    }
}

let loginInfobarId = "fd90eb33431b46f58a68720c36154b4a";
function displayConnectInfobar() {
    const userData = addinConfig.getUserData();

    const currentDisplayLanguage = UIStrings.getCurrentDisplayLanguage();
    const translations = UIStrings.getLocaleStrings(currentDisplayLanguage, userData, constants).infobar;

    Office.context.mailbox.item.notificationMessages.addAsync(loginInfobarId, {
        type: "insightMessage",
        message: translations.loginMessage,
        icon: "Icon.16x16",
        actions: [
            {
                actionType: "showTaskPane",
                actionText: translations.loginMessageActionText,
                commandId: "msgComposeShowTaskpane",
                contextData: "{''}",
            },
        ]
    });
}

function removeInsightInfobar() {
    Office.context.mailbox.item.notificationMessages.removeAsync(loginInfobarId);
}

async function adjustSignature(signatureToApply?: string): Promise<any> {
    try {
        const mailtasticSignatureInserted = await isMailtasticSignatureInserted();

        if (mailtasticSignatureInserted || signatureToApply) {
            const [to, cc] = await Promise.all([
                new Promise((resolve, reject) => {
                    Office.context.mailbox.item.to.getAsync(res => {
                        if (res.status === Office.AsyncResultStatus.Failed) {
                            reject(res.error);
                        } else {
                            resolve(res.value.map(r => r.emailAddress));
                        }
                    });
                }),
                new Promise((resolve, reject) => {
                    Office.context.mailbox.item.cc.getAsync(res => {
                        if (res.status === Office.AsyncResultStatus.Failed) {
                            reject(res.error);
                        } else {
                            resolve(res.value.map(r => r.emailAddress));
                        }
                    });
                })
            ]);

            const userData: UserData = Office.context.roamingSettings.get("userData");

            if (signatureToApply) {
                return getInjectedMessageBody(to, cc, signatureToApply, userData.Settings);
            } else {
                const appliedSignature = await getAppliedSignature(userData.Signatures);

                if (appliedSignature) {
                    let adjustedSignature = JSON.parse(JSON.stringify(appliedSignature));
                    adjustedSignature.HTML = getInjectedMessageBody(to, cc, adjustedSignature.HTML, userData.Settings);

                    await setSignature(adjustedSignature, true);
                } else {
                    console.error('Applied signature not found.');
                }
            }
        }
    } catch (error) {
        throw error;
    }
}

function getInjectedMessageBody(to, cc, body, userDataSettings: UserData["Settings"]) {
    try {
        const campaignImageSrcRegExPattern = RegExp(/(\/api\/(im|li|linkserve|picserve)\/.*?)"/, "gi");
        let uniqueEmailDomains = [],
            uniqueEmailDomains2 = [],
            uniqueEmailAddresses = [];

        const getUniqueEmailDomains = (to, cc) => {
            return [...(to || []), ...(cc || [])]
                .filter(email => email)
                .map(email => email.replace(/.*@/, ''))
                .reduce((t, y) => t.indexOf(y) > -1 ? t : [...t, y], []);
        };

        if (userDataSettings.appendDomains && !userDataSettings.appendFullAdress) {
            uniqueEmailDomains = getUniqueEmailDomains(to, cc);
        }

        if (userDataSettings.appendFullAdress) {
            uniqueEmailAddresses = [...(to || []), ...(cc || [])]
                .filter(email => email)
                .reduce((t, y) => t.indexOf(y) > -1 ? t : [...t, y], []);
        }

        if ((uniqueEmailDomains.length || uniqueEmailAddresses.length)) {
            let arr, domainPlaceholder = 'https://mailtastic.com';
            while ((arr = campaignImageSrcRegExPattern.exec(body)) !== null) {
                let noSearchParamsURL = (new URL(domainPlaceholder + arr[1])).pathname;

                let recDomainsString = '';
                if (uniqueEmailDomains.length && !(/(\?|&)+recdomains=/).test(noSearchParamsURL)) {
                    recDomainsString = 'recdomains=' + uniqueEmailDomains.join(',');
                }

                let recEmailsString = '';
                if (uniqueEmailAddresses.length && !(/(\?|&)+reccontacts=1/).test(noSearchParamsURL)) {
                    uniqueEmailDomains2 = getUniqueEmailDomains(to, cc);

                    recEmailsString = uniqueEmailDomains2.reduce((acc, domain) => {
                        return acc
                            + (acc.length ? '&' : '')
                            + domain
                            + '='
                            + uniqueEmailAddresses
                                .filter(email => email.includes('@' + domain))
                                .map(email => email.replace(/@.*/, ''))
                                .join(',');
                    }, '');
                    recEmailsString += '&reccontacts=1';
                }

                let emailStringData = '';
                if (recDomainsString) {
                    emailStringData = '?' + recDomainsString;
                } else if (recEmailsString) {
                    emailStringData = '?' + recEmailsString;
                }

                body = body.replace(arr[0], noSearchParamsURL + emailStringData + '"');
            }

            return body;
        } else {
            return body;
        }
    } catch (error) {
        throw error;
    }
}

function isOutlookHost() {
    if (!(Office && Office.context && Office.context.diagnostics)) {
        return false;
    }

    return Office.context.diagnostics.host === Office.HostType.Outlook;
}

function isOutlookOfficeOnlinePlatform() {
    if (!isOutlookHost()) {
        return false;
    }

    return Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline;
}

export default {
    isClientSignatureEnabled,
    disableClientSignature,
    setSignature,
    getSignatureList,
    setDefaultSignature,
    adjustSignature,
    isMailtasticSignatureInserted,
    highlightActiveSignature,
    removeInsightInfobar,
    displayConnectInfobarAsync,
    isOutlookHost,
    isOutlookOfficeOnlinePlatform
};