import "zone.js"; // Required for Angular
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import constants from "../helpers/constants";
import AppModule from "./app/app.module";

/* global console, document, Office */

Office.onReady((info) => {
    // TODO moved from initialize fn
    const sideloadMsgElem = document.getElementById("sideload-msg");
    const landingPageElem = document.getElementById("mt-landing-page-element");

    if (sideloadMsgElem) {
        sideloadMsgElem.style.display = "none";
    }
    if (landingPageElem) {
        landingPageElem.style.backgroundColor = constants.mailtastic
            ? "rgb(54, 63, 72)"
            : "#374785";
    }

    if (constants.environment === 'production') {
        enableProdMode();
    }

    // Bootstrap the app
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((error) => console.error(error));
});