/* global OfficeRuntime */
import * as sso from "office-addin-sso";

import { dialogFallback } from "./fallbackAuthHelper";
import { UserDataDev } from "../interfaces/data";
import * as localssohelper from "./localssohelper";
import constants from "./constants";

let retryGetAccessToken = 0;

export async function getAuthToken(noFallback = false) {
    try {
        // get bootstrap token
        let bootstrapToken: any = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true, forMSGraphAccess: true });

        // exchange bootstrap token for access token
        let exchangeResponse;
        // if (constants.environment === 'development') {
        //     exchangeResponse = await sso.getGraphToken(bootstrapToken);
        // } else {
        exchangeResponse = await localssohelper.getGraphTokenByBootstrapToken(bootstrapToken);
        // }

        if (exchangeResponse.claims) {
            // Microsoft Graph requires an additional form of authentication. Have the Office host
            // get a new token using the Claims string, which tells AAD to prompt the user for all
            // required forms of authentication.
            let mfaBootstrapToken: string = await OfficeRuntime.auth.getAccessToken({
                authChallenge: exchangeResponse.claims,
            });
            exchangeResponse = await sso.getGraphToken(mfaBootstrapToken);
        }

        if (exchangeResponse.error) {
            // AAD errors are returned to the client with HTTP code 200, so they do not trigger the catch block below.
            return handleAADErrors(exchangeResponse, noFallback);
        } else {
            return exchangeResponse.access_token;
        }
    } catch (exception) {
        // if handleClientSideErrors returns true then we will try to authenticate via the fallback
        // dialog rather than simply throw and error
        if (exception.code) {
            if (sso.handleClientSideErrors(exception)) {
                return new Promise((resolve, reject) => dialogFallback(resolve, reject));
            }
        } else {
            return Promise.reject(exception);  // ~ sso.showMessage
        }
    }
}

export async function getGraphData(noFallback?: boolean): Promise<UserDataDev> {
  try {
    const access_token = await getAuthToken(noFallback);

    // make call for signature list/user info
    let response: any = await fetch(`${constants.serverAPIURL}easysync/v2/outlookauthtoken`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({access_token})
    });

    if (response.status !== 200) {
        throw {code: response.status};
    }

    response = await response.json();

    return response;
  } catch (exception) {
      throw exception;
  }
}

function handleAADErrors(exchangeResponse: any, noFallback: boolean) {
  // On rare occasions the bootstrap token is unexpired when Office validates it,
  // but expires by the time it is sent to AAD for exchange. AAD will respond
  // with "The provided value for the 'assertion' is not valid. The assertion has expired."
  // Retry the call of getAccessToken (no more than once). This time Office will return a
  // new unexpired bootstrap token.

  if (exchangeResponse.error_description
      && exchangeResponse.error_description.indexOf("AADSTS500133") !== -1
      && retryGetAccessToken <= 0) {
    retryGetAccessToken++;
    return getAuthToken();
  } else {
      if (noFallback) {
          return Promise.reject({code: 12009});
      } else {
          return new Promise((resolve, reject) => dialogFallback(resolve, reject));
      }
  }
}
