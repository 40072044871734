import constants from './constants';

export async function getGraphTokenByBootstrapToken(bootstrapToken: string) {
    try {
        let response: any = await fetch(`${constants.serverAPIURL}easysync/v2/exchangetoken`, {
            method: 'GET',
            // mode: 'cors', // no-cors, *cors, same-origin  // TODO enable same-origin?
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                // 'Content-Type': 'application/json'
                Authorization: "Bearer " + bootstrapToken
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify({access_token})
        });

        if (response.status !== 200) {
            throw {code: response.status};
        }

        response = await response.json();

        return response;
    } catch (err) {
        throw new Error(`Error getting Graph token. \n${err}`);
    }
}