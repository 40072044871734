import { UserData } from "../interfaces/data";

function getUserData() {
    let userData: UserData;

    userData = Office.context.roamingSettings.get("userData");

    return userData;
}

function setUserData(userData: UserData, callback) {
    if (!userData || JSON.stringify(userData) === "{}") {
        Office.context.roamingSettings.remove("userData");
    } else {
        Office.context.roamingSettings.set("userData", userData);
    }

    Office.context.roamingSettings.saveAsync(callback);
}

export default {
    getUserData,
    setUserData
};