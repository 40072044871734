import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import URI from "urijs";

import Constants from "../../helpers/constants";

// images references in the manifest
import "../../../assets/favicon-APP_TARGET.ico";

import "../../../assets/mailtastic-16x16.png";
import "../../../assets/mailtastic-32x32.png";
import "../../../assets/mailtastic-80x80.png";
import "../../../assets/mailtastic-128x128.png";
import "../../../assets/Mailtastic-Logo.svg";

import "../../../assets/cognism-16x16.png";
import "../../../assets/cognism-32x32.png";
import "../../../assets/cognism-80x80.png";
import "../../../assets/cognism-128x128.png";
import "../../../assets/Cognism Logo.svg";

import addinConfig from "../../helpers/addin-config";
import addInHelpers from "../../helpers/add-in-helpers";
import { getAuthToken } from "../../helpers/ssoauthhelper";
import { UserData } from "../../interfaces/data";
import UIStrings from "../../helpers/translations";

const template = require("./app.component.html");

let settingsDialog;

@Component({
    selector: "app-home",
    template
})
export default class AppComponent implements OnInit {
    public taskpaneFlags = {
        notLoggedIn: false,
        showInfo: false,
        loadingSignatures: false,
        comingSoon: false
    };
    public taskpaneModel = {
        githubUser: '',
        defaultGistId: '',
        gists: [],
        signatures: [],
        errorText: '',
        onErrorOk: () => {}
    };
    public userData: UserData;
    public templateData;
    public constants = Constants;

    constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {}

    public async ngOnInit() {
        if (addInHelpers.isOutlookOfficeOnlinePlatform()) {
            try {
                this.userData = addinConfig.getUserData();

                const currentDisplayLanguage = UIStrings.getCurrentDisplayLanguage();
                this.templateData = UIStrings.getLocaleStrings(currentDisplayLanguage, this.userData, this.constants).taskpane;
                this.cd.detectChanges();

                if (this.userData && this.userData.Settings) {
                    this.taskpaneModel.signatures = [];
                    await this.loadSignatures();
                } else {
                    this.taskpaneFlags.notLoggedIn = true;
                    this.taskpaneModel.signatures = [];
                }

                setInterval(async () => {
                    await addInHelpers.highlightActiveSignature(this.taskpaneModel.signatures);
                }, 2000);
            } catch (error) {
                this.showError(error);
            }
        } else {
            this.taskpaneFlags.comingSoon = true;
        }
    }

    public onOpenSettingsBtnClick() {
        let url = new URI('/dialog.html').absoluteTo(window.location).toString();

        let dialogOptions = { width: 40, height: 50, displayInIframe: true };

        Office.context.ui.displayDialogAsync(url, dialogOptions, result => {
            if (result.value) {
                settingsDialog = result.value;
                settingsDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.receiveMessage.bind(this));
                settingsDialog.addEventHandler(Office.EventType.DialogEventReceived, this.dialogClosed.bind(this));
            } else {
                this.showError(result.error);
            }
        });
    }

    public async signIn() {
        this.taskpaneFlags.notLoggedIn = false;
        this.taskpaneFlags.loadingSignatures = true;
        this.cd.detectChanges();

        try {
            await getAuthToken(false);
            await this.loadSignatures();
        } catch (error) {
            this.showError(error);
        }
    }

    public async onSignatureApply(signature: UserData['Signatures'][0]) {
        try {
            await addInHelpers.setSignature(signature);
            await addInHelpers.highlightActiveSignature(this.taskpaneModel.signatures);
            this.cd.detectChanges();  // TODO needed?
        } catch (error) {
            this.showError(error);
        }
    }

    public async onClearSignature() {
        try {
            await addInHelpers.setSignature(({HTML: ''} as any));
            await addInHelpers.highlightActiveSignature(this.taskpaneModel.signatures);
        } catch (error) {
            this.showError(error);
        }
    }

    private async loadSignatures() {
        try {
            this.taskpaneModel.errorText = '';
            this.taskpaneFlags.notLoggedIn = false;

            this.taskpaneFlags.loadingSignatures = true;
            await addInHelpers.highlightActiveSignature(this.taskpaneModel.signatures);
            this.cd.detectChanges();

            const userData: UserData = await addInHelpers.getSignatureList(true);

            if (userData) {
                this.userData = userData;
                this.taskpaneModel.signatures = userData.Signatures;

                addInHelpers.removeInsightInfobar();

                let isClientSignatureEnabled = await addInHelpers.isClientSignatureEnabled();
                if (isClientSignatureEnabled) {
                    await addInHelpers.disableClientSignature();
                }
            }

            const mailtasticSignatureInserted = await addInHelpers.isMailtasticSignatureInserted();

            if (!mailtasticSignatureInserted) {
                await addInHelpers.setDefaultSignature();
            }

            await addInHelpers.highlightActiveSignature(this.taskpaneModel.signatures);

            this.taskpaneFlags.loadingSignatures = false;
            this.cd.detectChanges();
        } catch (error) {
            throw error;
        }
    }

    private receiveMessage(message) {
        message = JSON.parse(message.message);

        if (message.message && message.message === 'send-data') {
            settingsDialog.messageChild(JSON.stringify(this.userData || {}));
        } else {
            addinConfig.setUserData(message, () => {
                settingsDialog.close();
                settingsDialog = null;
                this.ngOnInit();
                this.cd.detectChanges();
            });
        }
    }

    private dialogClosed(message) {
        settingsDialog = null;
    }

    private showError(error) {
        if (!error) {
            console.error('No error provided. Please check.');
            return;
        }

        // ~ same as in commands.ts
        if (error.code === 12009 || error.errorCode === 'consent_required') {  // user consent denied
            this.taskpaneFlags.notLoggedIn = false;
            this.taskpaneFlags.loadingSignatures = false;
            this.taskpaneModel.errorText = this.templateData.login.providePermissionMessage;
            this.taskpaneModel.signatures = [];
            this.taskpaneModel.onErrorOk = () => {
                this.taskpaneFlags.notLoggedIn = true;
                this.taskpaneFlags.loadingSignatures = false;
                this.taskpaneModel.errorText = '';
            };
        } else if (error.message === 'Failed to fetch' || error.code === 500) {  // server error
            this.taskpaneFlags.notLoggedIn = false;
            this.taskpaneFlags.loadingSignatures = false;
            this.taskpaneModel.errorText = this.templateData.login.serverDownMessage;
            this.taskpaneModel.signatures = [];
            this.taskpaneModel.onErrorOk = () => {
                this.ngOnInit();
                this.taskpaneModel.errorText = '';
            };
        } else if (error.code === 401 || error.code === 404) {  // user not authorized with Outlook/server
            this.taskpaneFlags.notLoggedIn = false;
            this.taskpaneFlags.loadingSignatures = false;
            this.taskpaneModel.errorText = this.templateData.login.notAuthorizedMessage;
            this.taskpaneModel.signatures = [];
            this.taskpaneModel.onErrorOk = () => {
                this.taskpaneFlags.notLoggedIn = true;
                this.taskpaneFlags.loadingSignatures = false;
                this.taskpaneModel.errorText = '';
            };
        } else {  // unknown error
            console.error(error);
            this.taskpaneFlags.notLoggedIn = false;
            this.taskpaneFlags.loadingSignatures = false;
            this.taskpaneModel.errorText = error.message || error.errorMessage || this.templateData.login.generalErrorMessage;  // TODO dev only, use general error message for displaying to the clients and send full error to the server?
            this.taskpaneModel.signatures = [];
            this.taskpaneModel.onErrorOk = () => {
                this.ngOnInit();
                this.taskpaneModel.errorText = '';
            };
        }
    }
}
