/* global console, location, Office */

let loginDialog;

export function dialogFallback(resolve, reject) {
  // We fall back to Dialog API for any error.
  const url = "/fallbackauthdialog.html";
  showLoginPopup(url, resolve, reject);
}

// This handler responds to the success or failure message that the pop-up dialog receives from the identity provider
// and access token provider.
async function processMessage(arg, resolve, reject) {
  let messageFromDialog = JSON.parse(arg.message);

  if (messageFromDialog.status === "success") {
    // We now have a valid access token.
    loginDialog.close();
    resolve(messageFromDialog.result);
  } else {
    // Something went wrong with authentication or the authorization of the web application.
    loginDialog.close();
    reject(messageFromDialog.result);
  }
}

async function processEvent(arg, resolve, reject) {
    if (!arg) {
        reject();
    }

    switch (arg.error) {
        case 12006: {  // dialog closed
            reject({code: 12009});
            break;
        }
        default: {
            reject();
        }
    }
}

// Use the Office dialog API to open a pop-up and display the sign-in page for the identity provider.
function showLoginPopup(url, resolve, reject) {
  let fullUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + url;

  // height and width are percentages of the size of the parent Office application, e.g. Oulook, etc.
  Office.context.ui.displayDialogAsync(fullUrl, { height: 60, width: 30 }, result => {
      if (result.value) {
          loginDialog = result.value;
          loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, arg => processMessage(arg, resolve, reject));
          loginDialog.addEventHandler(Office.EventType.DialogEventReceived, arg => processEvent(arg, resolve, reject));
      } else {
          reject(result.error);
      }
  });
}
