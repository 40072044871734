import { IUIStrings } from '../interfaces/ui-strings';

const UIStrings = (function () {
    "use strict";

    let UIStrings: IUIStrings = ({} as IUIStrings);

    UIStrings.EN = (userData, hasUserData, constants) => {
        if (!constants || constants.mailtastic) {
            return {
                infobar: {
                    loginMessage: "Please login with the Mailtastic Add-In to get your signatures.",
                    loginMessageActionText: "Show"
                },
                dialog: {
                    closeBtnText: "Close",
                    menuItems: [
                        {
                            active: true,
                            icon: '../../../assets/icon_refresh.png',
                            label: "Synchronisation",
                            id: 'mailtastic-sync-link',
                            content:
                                {
                                    header: "Synchronisation",
                                    hello: "Hello",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Mailtastic Outlook Add-In ensures that your emails are always sent with the latest version of company signature and campaigns.",
                                    activeEmailAccounts: "Active Outlook email accounts",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Available Mailtastic signatures",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_personaldata.png',
                            label: "Personal Data",
                            id: 'mailtastic-personal-data-link',
                            content:
                                {
                                    header: "Personal Data",
                                    administrationPage: "This area takes you to the administration page for your personal email signature data. Please keep these data up to date.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Edit personal data",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_help.png',
                            label: "Help",
                            id: 'mailtastic-help-link',
                            content:
                                {
                                    header: "Help",
                                    contactPerson: "Contact person within your company",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        We would be pleased to help you personally. If you have any questions, please send us an email to <a target="_blank" href="mailto:support@mailtastic.com?subject=Outlook%20Add-in%20support%20question">support@mailtastic.com</a> or use the free chat on our website, <a href="https://www.mailtastic.com" target="_blank">www.mailtastic.com</a>.
                                    `,
                                    signature: "Your Mailtastic Team",
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_info.png',
                            label: "Info",
                            id: 'mailtastic-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Mailtastic Outlook Add-In is a product of NETSTAG GmbH",
                                    companyData: `
                                        NETSTAG GmbH<br>
                                        Große Bleiche 10<br>
                                        55116 Mainz<br>
                                        Germany<br>
                                        <a target="_blank" href="mailto:hello@mailtastic.com?subject=Hello%20from%20Outlook%20Add-in">hello@mailtastic.com</a>
                                    `,
                                    rightsReserved: "NETSTAG GmbH - All rights reserved",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Mailtastic Outlook Add-In logo",
                    loggedInAs: "Logged in as",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Logout"
                },
                taskpane: {
                    login: {
                        greeting: "Welcome!",
                        message: "Please <strong>Sign In</strong> to authenticate with Mailtastic Outlook Add-In",
                        signInBtn: "Sign In",
                        retryBtn: "Retry",
                        providePermissionMessage: "Please grant required permissions to the add-in.",
                        serverDownMessage: "Server connection issue. Please retry.",
                        generalErrorMessage: "An error occurred. Please retry.",
                        notAuthorizedMessage: "User not authorized. Please retry with another account."
                    },
                    signatureList: {
                        loadingMessage: "Loading signatures, please wait.",
                        infoMessage: "<p>Here are the signatures set up for you. Select any signature below to insert it in this email.</p>" +
                            "<p>Default signatures for new and reply messages will be applied automatically.</p>"
                    }
                }
            };
        } else if (constants.cognism) {
            return {
                infobar: {
                    loginMessage: "Please login with the Cognism Add-In to get your signatures.",
                    loginMessageActionText: "Show"
                },
                dialog: {
                    closeBtnText: "Close",
                    menuItems: [
                        {
                            active: true,
                            icon: '../../../assets/icon_refresh.png',
                            label: "Synchronisation",
                            id: 'cognism-sync-link',
                            content:
                                {
                                    header: "Synchronisation",
                                    hello: "Hello",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Cognism Outlook Add-In ensures that your emails are always sent with the latest version of company signature and campaigns.",
                                    activeEmailAccounts: "Active Outlook email accounts",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Available Cognism signatures",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_personaldata.png',
                            label: "Personal Data",
                            id: 'cognism-personal-data-link',
                            content:
                                {
                                    header: "Personal Data",
                                    administrationPage: "This area takes you to the administration page for your personal email signature data. Please keep these data up to date.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Edit personal data",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_help.png',
                            label: "Help",
                            id: 'cognism-help-link',
                            content:
                                {
                                    header: "Help",
                                    contactPerson: "Contact person within your company",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        We would be pleased to help you personally. If you have any questions, please send us an email to <a target="_blank" href="mailto:signatures@cognism.com?subject=Outlook%20Add-in%20support%20question">signatures@cognism.com</a> or use the free chat on our website, <a href="https://www.cognism.com/signatures" target="_blank">www.cognism.com/signatures</a>.
                                    `,
                                    signature: "Your Cognism Signatures Team",
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_info.png',
                            label: "Info",
                            id: 'cognism-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Cognism Outlook Add-In is a product of Cognism",
                                    companyData: `
                                        Cognism<br>
                                        12 Times Court Retreat Road<br>
                                        Richmond, TW9 1AF<br>
                                        England<br>
                                        <a target="_blank" href="mailto:sigsupport@cognism.com?subject=Hello%20from%20Outlook%20Add-in">sigsupport@cognism.com</a>
                                    `,
                                    rightsReserved: "Cognism - All rights reserved",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Cognism Outlook Add-In logo",
                    loggedInAs: "Logged in as",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Logout"
                },
                taskpane: {
                    login: {
                        greeting: "Welcome!",
                        message: "Please <strong>Sign In</strong> to authenticate with Cognism Outlook Add-In",
                        signInBtn: "Sign In",
                        retryBtn: "Retry",
                        providePermissionMessage: "Please grant required permissions to the add-in.",
                        serverDownMessage: "Server connection issue. Please retry.",
                        generalErrorMessage: "An error occurred. Please retry.",
                        notAuthorizedMessage: "User not authorized. Please retry with another account."
                    },
                    signatureList: {
                        loadingMessage: "Loading signatures, please wait.",
                        infoMessage: "<p>Here are the signatures set up for you. Select any signature below to insert it in this email.</p>" +
                            "<p>Default signatures for new and reply messages will be applied automatically.</p>"
                    }
                }
            };
        }
    };

    UIStrings.DE = (userData, hasUserData, constants) => {
        if (!constants || constants.mailtastic) {
            return {
                infobar: {
                    loginMessage: "Bitte loggen Sie sich mit dem Mailtastic Add-In ein, um Ihre Signaturen zu erhalten.",
                    loginMessageActionText: "Zeigne"
                },
                dialog: {
                    closeBtnText: "Schließen",
                    menuItems: [
                        {
                            active: true,
                            icon: '../../../assets/icon_refresh.png',
                            label: "Synchronisation",
                            id: 'mailtastic-sync-link',
                            content:
                                {
                                    header: "Synchronisation",
                                    hello: "Hallo",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Mailtastic Outlook-Add-In stellt sicher, dass Ihre E-Mails immer mit der neuesten Version der Unternehmenssignatur und -kampagnen gesendet werden..",
                                    activeEmailAccounts: "Aktive Outlook-E-Mail-Konten",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Verfügbare Signaturen",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_personaldata.png',
                            label: "Persönliche Daten",
                            id: 'mailtastic-personal-data-link',
                            content:
                                {
                                    header: "Persönliche Daten",
                                    administrationPage: "In diesem Bereich gelangen Sie auf die Verwaltungsseite für Ihre persönlichen E-Mail-Signaturdaten. Bitte halten Sie diese Daten auf dem neuesten Stand.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Bearbeiten Sie persönliche Daten",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_help.png',
                            label: "Hilfe",
                            id: 'mailtastic-help-link',
                            content:
                                {
                                    header: "Hilfe",
                                    contactPerson: "Ansprechpartner in Ihrer Firma",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        Wir würden uns freuen, Ihnen persönlich zu helfen. Wenn Sie Fragen haben, senden Sie uns bitte eine Email an <a target="_blank" href="mailto:support@mailtastic.com?subject=Outlook%20Add-in%20support%20question">support@mailtastic.com</a> oder nutze den kostenlosen Chat auf unserer Website, <a href="https://www.mailtastic.com" target="_blank">www.mailtastic.com</a>.
                                    `,
                                    signature: "Dein Mailtastic Team",
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_info.png',
                            label: "Info",
                            id: 'mailtastic-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Mailtastic Outlook-Add-In ist ein Produkt der NETSTAG GmbH",
                                    companyData: `
                                        NETSTAG GmbH<br>
                                        Große Bleiche 10<br>
                                        55116 Mainz<br>
                                        Deutschland<br>
                                        <a target="_blank" href="mailto:hello@mailtastic.com?subject=Hello%20from%20Outlook%20Add-in">hello@mailtastic.com</a>
                                    `,
                                    rightsReserved: "NETSTAG GmbH - Alle Rechte vorbehalten",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Mailtastic Outlook-Add-In-Logo",
                    loggedInAs: "Angemeldet als",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Ausloggen"
                },
                taskpane: {
                    login: {
                        greeting: "Willkommen!",
                        message: "Bitte <strong>Melden Sie sich an</strong>, um sich beim Mailtastic Outlook Add-In zu authentifizieren",
                        signInBtn: "Einloggen",
                        retryBtn: "Wiederholen",
                        providePermissionMessage: "Bitte erteilen Sie dem Add-In die erforderlichen Berechtigungen.",
                        serverDownMessage: "Problem mit der Serververbindung. Bitte erneut versuchen.",
                        generalErrorMessage: "Ein Fehler ist aufgetreten. Bitte erneut versuchen.",
                        notAuthorizedMessage: "Benutzer nicht autorisiert. Bitte versuchen Sie es mit einem anderen Konto erneut."
                    },
                    signatureList: {
                        loadingMessage: "Signaturen werden geladen, bitte warten.",
                        infoMessage: "<p>Hier sind die für Sie eingerichteten Signaturen. Wählen Sie unten eine beliebige Signatur aus, um sie in diese E-Mail einzufügen.</p>" +
                            "<p>Standardsignaturen für neue und antwortende Nachrichten werden automatisch angewendet.</p>"
                    }
                }
            };
        } else if (constants.cognism) {
            return {
                infobar: {
                    loginMessage: "Bitte loggen Sie sich mit dem Cognism Add-In ein, um Ihre Signaturen zu erhalten.",
                    loginMessageActionText: "Zeigne"
                },
                dialog: {
                    closeBtnText: "Schließen",
                    menuItems: [
                        {
                            active: true,
                            icon: '../../../assets/icon_refresh.png',
                            label: "Synchronisation",
                            id: 'cognism-sync-link',
                            content:
                                {
                                    header: "Synchronisation",
                                    hello: "Hallo",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Cognism Outlook-Add-In stellt sicher, dass Ihre E-Mails immer mit der neuesten Version der Unternehmenssignatur und -kampagnen gesendet werden..",
                                    activeEmailAccounts: "Aktive Outlook-E-Mail-Konten",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Verfügbare Signaturen",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_personaldata.png',
                            label: "Persönliche Daten",
                            id: 'cognism-personal-data-link',
                            content:
                                {
                                    header: "Persönliche Daten",
                                    administrationPage: "In diesem Bereich gelangen Sie auf die Verwaltungsseite für Ihre persönlichen E-Mail-Signaturdaten. Bitte halten Sie diese Daten auf dem neuesten Stand.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Bearbeiten Sie persönliche Daten",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_help.png',
                            label: "Hilfe",
                            id: 'cognism-help-link',
                            content:
                                {
                                    header: "Hilfe",
                                    contactPerson: "Ansprechpartner in Ihrer Firma",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        Wir würden uns freuen, Ihnen persönlich zu helfen. Wenn Sie Fragen haben, senden Sie uns bitte eine Email an <a target="_blank" href="mailto:signatures@cognism.com?subject=Outlook%20Add-in%20support%20question">signatures@cognism.com</a> oder nutze den kostenlosen Chat auf unserer Website, <a href="https://www.cognism.com/signatures" target="_blank">www.cognism.com/signatures</a>.
                                    `,
                                    signature: "Ihr Cognism Signatures Team",
                                }
                        },
                        {
                            active: false,
                            icon: '../../../assets/icon_info.png',
                            label: "Info",
                            id: 'cognism-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Cognism Outlook-Add-In ist ein Produkt der Cognism",
                                    companyData: `
                                        Cognism<br>
                                        12 Times Court Retreat Road<br>
                                        Richmond, TW9 1AF<br>
                                        England<br>
                                        <a target="_blank" href="mailto:sigsupport@cognism.com?subject=Hello%20from%20Outlook%20Add-in">sigsupport@cognism.com</a>
                                    `,
                                    rightsReserved: "Cognism - Alle Rechte vorbehalten",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Cognism Outlook-Add-In-Logo",
                    loggedInAs: "Angemeldet als",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Ausloggen"
                },
                taskpane: {
                    login: {
                        greeting: "Willkommen!",
                        message: "Bitte <strong>Melden Sie sich an</strong>, um sich beim Cognism Outlook Add-In zu authentifizieren",
                        signInBtn: "Einloggen",
                        retryBtn: "Wiederholen",
                        providePermissionMessage: "Bitte erteilen Sie dem Add-In die erforderlichen Berechtigungen.",
                        serverDownMessage: "Problem mit der Serververbindung. Bitte erneut versuchen.",
                        generalErrorMessage: "Ein Fehler ist aufgetreten. Bitte erneut versuchen.",
                        notAuthorizedMessage: "Benutzer nicht autorisiert. Bitte versuchen Sie es mit einem anderen Konto erneut."
                    },
                    signatureList: {
                        loadingMessage: "Signaturen werden geladen, bitte warten.",
                        infoMessage: "<p>Hier sind die für Sie eingerichteten Signaturen. Wählen Sie unten eine beliebige Signatur aus, um sie in diese E-Mail einzufügen.</p>" +
                            "<p>Standardsignaturen für neue und antwortende Nachrichten werden automatisch angewendet.</p>"
                    }
                }
            };
        }
    };

    UIStrings.RO = (userData, hasUserData, constants) => {
        if (!constants || constants.mailtastic) {
            return {
                infobar: {
                    loginMessage: "Vă rugăm să vă conectați la Mailtastic Add-In pentru a obține semnăturile dvs.",
                    loginMessageActionText: "Deschide"
                },
                dialog: {
                    closeBtnText: "Inchide",
                    menuItems: [
                        {
                            active: true,
                            icon: "../../../assets/icon_refresh.png",
                            label: "Sincronizare",
                            id: 'mailtastic-sync-link',
                            content:
                                {
                                    header: "Sincronizare",
                                    hello: "Salut",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Mailtastic Outlook Add-In asigura trimiterea e-mail-urilor dumneavoastra cu cea mai recenta versiune a semnaturii companiei si campaniilor.",
                                    activeEmailAccounts: "Conturile active din Outlook",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Semnaturile Mailtastic disponibile",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_personaldata.png",
                            label: "Date personale",
                            id: 'mailtastic-personal-data-link',
                            content:
                                {
                                    header: "Date personale",
                                    administrationPage: "Această zonă vă duce la pagina de administrare a datelor personale de semnătură pentru e-mail. Vă rugăm să mentineți actualizate aceste date.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Editați datele personale",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_help.png",
                            label: "Ajutor",
                            id: 'mailtastic-help-link',
                            content:
                                {
                                    header: "Ajutor",
                                    contactPerson: "Persoana de contact din cadrul companiei dvs.",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        Vom fi bucuroși să vă ajutăm personal. Dacă aveți întrebări, vă rugăm să ne trimiteți un e-mail la <a target="_blank" href="mailto:support@mailtastic.com?subject=Outlook%20Add-in%20support%20question">support@mailtastic.com</a> sau utilizați chat-ul gratuit de pe site-ul nostru, <a href="https://www.mailtastic.com" target="_blank">www.mailtastic.com</a>.
                                    `,
                                    signature: "Echipa Mailtastic",
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_info.png",
                            label: "Info",
                            id: 'mailtastic-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Mailtastic Outlook Add-In este un produs al firmei NETSTAG GmbH",
                                    companyData: `
                                        NETSTAG GmbH<br>
                                        Große Bleiche 10<br>
                                        55116 Mainz<br>
                                        Germania<br>
                                        <a target="_blank" href="mailto:hello@mailtastic.com?subject=Hello%20from%20Outlook%20Add-in">hello@mailtastic.com</a>
                                    `,
                                    rightsReserved: "NETSTAG GmbH - Toate drepturile rezervate",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Mailtastic Outlook Add-In logo",
                    loggedInAs: "Conectat ca",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Deconectare"
                },
                taskpane: {
                    login: {
                        greeting: "Bine ați venit!",
                        message: "Vă rugăm să vă <strong>Conectați</strong> pentru a vă autentifica în Mailtastic Outlook Add-In",
                        signInBtn: "Conectare",
                        retryBtn: "Reîncercați",
                        providePermissionMessage: "Vă rugăm să acordați permisiunile necesare pentru add-in.",
                        serverDownMessage: "Problemă conexiune server. Vă rugăm să reîncercați.",
                        generalErrorMessage: "A apărut o eroare. Vă rugăm să reîncercați.",
                        notAuthorizedMessage: "Utilizatorul nu este autorizat. Încercați din nou cu un alt cont."
                    },
                    signatureList: {
                        loadingMessage: "Se încarcă semnături, așteptați.",
                        infoMessage: "<p>Iată semnăturile create pentru dvs. Selectați orice semnătură de mai jos pentru a o insera în acest e-mail.</p>" +
                            "<p>Semnăturile implicite pentru mesajele noi și cele de răspuns vor fi aplicate automat.</p>"
                    }
                }
            };
        } else if (constants.cognism) {
            return {
                infobar: {
                    loginMessage: "Vă rugăm să vă conectați la Cognism Add-In pentru a obține semnăturile dvs.",
                    loginMessageActionText: "Deschide"
                },
                dialog: {
                    closeBtnText: "Inchide",
                    menuItems: [
                        {
                            active: true,
                            icon: "../../../assets/icon_refresh.png",
                            label: "Sincronizare",
                            id: 'cognism-sync-link',
                            content:
                                {
                                    header: "Sincronizare",
                                    hello: "Salut",
                                    userName: hasUserData ? userData.Settings.userName : "",
                                    easySyncUpdatedSignatures: "Cognism Outlook Add-In asigura trimiterea e-mail-urilor dumneavoastra cu cea mai recenta versiune a semnaturii companiei si campaniilor.",
                                    activeEmailAccounts: "Conturile active din Outlook",
                                    validEmailAddresses: hasUserData ? userData.Settings.validEmailAddresses : [],
                                    availableSignature: "Semnaturile Cognism disponibile",
                                    availableSignatures: hasUserData ? userData.Signatures.map(s => s.Title) : []
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_personaldata.png",
                            label: "Date personale",
                            id: 'cognism-personal-data-link',
                            content:
                                {
                                    header: "Date personale",
                                    administrationPage: "Această zonă vă duce la pagina de administrare a datelor personale de semnătură pentru e-mail. Vă rugăm să mentineți actualizate aceste date.",
                                    userEmail: hasUserData ? userData.Settings.email : "",
                                    editPersonalData: "Editați datele personale",
                                    editPersonalDataLink: hasUserData ? userData.Settings.personalDataEditLink : ""
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_help.png",
                            label: "Ajutor",
                            id: 'cognism-help-link',
                            content:
                                {
                                    header: "Ajutor",
                                    contactPerson: "Persoana de contact din cadrul companiei dvs.",
                                    contactPersonName: hasUserData ? userData.Settings.adminUserName : "",
                                    contactPersonEmail: hasUserData ? userData.Settings.adminEmail : "",
                                    helpMessage: `
                                        Vom fi bucuroși să vă ajutăm personal. Dacă aveți întrebări, vă rugăm să ne trimiteți un e-mail la <a target="_blank" href="mailto:signatures@cognism.com?subject=Outlook%20Add-in%20support%20question">signatures@cognism.com</a> sau utilizați chat-ul gratuit de pe site-ul nostru, <a href="https://www.cognism.com/signatures" target="_blank">www.cognism.com/signatures</a>.
                                    `,
                                    signature: "Echipa Cognism Semnaturi",
                                }
                        },
                        {
                            active: false,
                            icon: "../../../assets/icon_info.png",
                            label: "Info",
                            id: 'cognism-info-link',
                            content:
                                {
                                    header: "Info",
                                    productOf: "Cognism Outlook Add-In este un produs al firmei Cognism",
                                    companyData: `
                                        Cognism<br>
                                        12 Times Court Retreat Road<br>
                                        Richmond, TW9 1AF<br>
                                        Anglia<br>
                                        <a target="_blank" href="mailto:sigsupport@cognism.com?subject=Hello%20from%20Outlook%20Add-in">sigsupport@cognism.com</a>
                                    `,
                                    rightsReserved: "Cognism - Toate drepturile rezervate",
                                    version: constants ? constants.version : '-'
                                }
                        }
                    ],
                    easySyncLogo: "Cognism Outlook Add-In logo",
                    loggedInAs: "Conectat ca",
                    userName: hasUserData ? userData.Settings.userName : "",
                    logout: "Deconectare"
                },
                taskpane: {
                    login: {
                        greeting: "Bine ați venit!",
                        message: "Vă rugăm să vă <strong>Conectați</strong> pentru a vă autentifica în Cognism Outlook Add-In",
                        signInBtn: "Conectare",
                        retryBtn: "Reîncercați",
                        providePermissionMessage: "Vă rugăm să acordați permisiunile necesare pentru add-in.",
                        serverDownMessage: "Problemă conexiune server. Vă rugăm să reîncercați.",
                        generalErrorMessage: "A aparut o eroare. Vă rugăm să reîncercați.",
                        notAuthorizedMessage: "Utilizatorul nu este autorizat. Încercați din nou cu un alt cont."
                    },
                    signatureList: {
                        loadingMessage: "Se încarcă semnături, așteptați.",
                        infoMessage: "<p>Iată semnăturile create pentru dvs. Selectați orice semnătură de mai jos pentru a o insera în acest e-mail.</p>" +
                            "<p>Semnăturile implicite pentru mesajele noi și cele de răspuns vor fi aplicate automat.</p>"
                    }
                }
            };
        }
    };

    UIStrings.getLocaleStrings = (locale, userData, constants) => {
        let text;
        const hasUserData = !!(userData && userData.Settings);

        // Get the resource strings that match the language
        switch (locale) {
            case 'en-US':
                text = UIStrings.EN(userData, hasUserData, constants);
                break;
            case 'de-DE':
                text = UIStrings.DE(userData, hasUserData, constants);
                break;
            case 'ro-RO':
                text = UIStrings.RO(userData, hasUserData, constants);
                break;
            default:
                text = UIStrings.EN(userData, hasUserData, constants);
                break;
        }

        return text;
    };

    /**
     * displayLanguage, for client UI language
     * contentLanguage, for e-mail compose/proofing language
     */
    UIStrings.getCurrentDisplayLanguage = () => {
        return Office.context.displayLanguage;
    };

    return UIStrings;
})();

export default UIStrings;