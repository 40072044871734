"use strict";

const Constants = {
    mailtastic: __mailtastic__,
    cognism: __cognism__,
    version: __version__,
    serverHostingURL: __serverHostingURL__,
    serverAPIURL: __serverAPIURL__,
    applicationGUID: __applicationGUID__,
    environment: __environment__
};

export default Constants;
